import 'bootstrap';
import Swiper from 'swiper/bundle';

setTimeout(function () {
    // Pre Loader
    document.querySelector(".pre-loader")
        .style.opacity = "0";

    document.querySelector(".pre-loader")
        .addEventListener("transitionend", function () {
            document.querySelector(".pre-loader").style.display = "none";
        });
    // End Pre Loader

    document.getElementById('video-apresentation').play();
}, 250);

new Swiper('.main-slide .swiper-container', {
    speed: 1e3,
    effect: "fade",
    autoplay: {
        delay: 5e3,
        disableOnInteraction: !1
    },
    navigation: {
        nextEl: ".slides .swiper-button-next",
        prevEl: ".slides .swiper-button-prev"
    },
    pagination: {
        clickable: true,
        el: '.slides .swiper-pagination',
        dynamicBullets: true
    },
});

new Swiper('.team-slide .swiper-container', {
    speed: 3e3,
    autoplay: {
        delay: 5e3,
        disableOnInteraction: !1
    },
    navigation: {
        nextEl: ".team-slide .swiper-button-next",
        prevEl: ".team-slide .swiper-button-prev"
    },
});

new Swiper('.blog-slide .swiper-container', {
    slidesPerView: 1,
    spaceBetween: 30,
    navigation: {
        nextEl: ".blog-slide .swiper-button-next",
        prevEl: ".blog-slide .swiper-button-prev"
    },
    breakpoints: {
        0: {
            slidesPerView: 1,
        },
        768: {
            slidesPerView: 2,
        },
    },
});

new Swiper('.testimonials-slide .swiper-container', {
    speed: 3e3,
    autoplay: {
        delay: 5e3,
        disableOnInteraction: !1
    },
    slidesPerView: 1,
    navigation: {
        nextEl: ".testimonials-slide .swiper-button-next",
        prevEl: ".testimonials-slide .swiper-button-prev"
    },
});

const formContact = document.querySelector(".form-contact");
function formContactSubmit(event) {
    document.querySelector(".form-contact .btn-submit").disabled = true;
}
formContact.addEventListener('submit', formContactSubmit);
